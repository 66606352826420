<template>
  <News />
</template>

<script>
import News from "@/components/News/News";
export default {
  components: {
    News
  }
};
</script>
