<template>
  <v-container fluid>
    <h1>News:</h1>
    <v-row>
      <v-col cols="12" md="6" v-for="(post, index) in posts" :key="index">
        <Post
          :del="del"
          :delFunc="() => $fiery.remove(post)"
          :description="post.description"
          :name="post.name"
        >
        </Post>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "@/plugins/firebase";
import Post from "@/components/News/Post";

export default {
  props: {
    del: {
      type: Boolean,
      default: false
    }
  },
  fiery: true,
  components: { Post },
  data() {
    return {
      posts: this.$fiery(db.collection("News"), {
        query: post => post.orderBy("date")
      })
    };
  }
};
</script>
