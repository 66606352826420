<template>
  <v-card class="mx-auto my-1">
    <v-card-title>{{ name }}</v-card-title>

    <v-card-text class="text--primary">
      {{ description }}
    </v-card-text>
    <v-fab-transition v-if="del">
      <v-btn small fab absolute top right color="error" @click="delFunc">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-card>
</template>

<script>
export default {
  props: {
    delFunc: { type: Function, default: () => {} },
    del: { type: Boolean, default: false },
    name: { type: String, required: true },
    description: { type: String, required: true }
  }
};
</script>
